import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
        'registrati': "Registrati",
        'password_dimenticata': "Password dimenticata?",
        'accedi': "Accedi",
        'email': "E-mail",
        'password': "Password",
        "termini_condizioni": "Termini e condizioni",
        "conferma": "Conferma",
        "salva": "Salva",
        "faq": "F.A.Q",
        "subTitleFaq": "Qui puoi trovare le risposte alle domande più frequenti.",
        "torna_indietro": "Torna indietro",
        "401_subtitle": "Stai provando ad accedere ad una pagina che richiede l'autenticazione, prosegui la navigazione usando la barra in alto o torna indietro.",
        "401_subtitle_admin": "Non hai i permessi sufficienti per visualizzare questa pagina.",
        "401_title": "Errore 401 non sei autorizzato",
        "erroreServer": "Errore server",
        "500_title": "Errore 500 problema interno del server",
        "500_subtitle": "La richiesta che hai inviato, non è andata a buon fine, riprova o contatta l'assistenza del servizio tecnico, descrivendo il problema.",
        "non_trovato": "Non trovato",
        "404_title": "Errore 404 risorsa cercata non trovata",
        "404_subtitle": "Il percorso ricerca non è stato trovato, controlla di aver digitato correttamente il link, riprova.",
        "logout": "Logout",
        "vai": "Vai",
        "overview":"Home",
        "problemi_di_elaborazione":"Problemi di elaborazione,riprova",
        "temperature": "Temperatura",
        "humidity": "Umidità",
        "wind":"Vento",
        "pressure": "Pressione",
        "rain": "Pioggia",
        "attuale": "Attuale",
        "massima": "Massima",
        "minima": "Minima",
        "dew_point": "Dew point",
        "wind_chill": "Wind chill",
        "giorno": "Giorno",
        "mese": "Mese",
        "anno": "Anno",
        "umidity":"Umidità",
        "radar":"Radar",
        "webcam":"Webcam",
        "idrometro":"Idrometro",
        "centraline_online":"Centraline online",
        "idrometri_online":"Idrometri online",
        "pioggia_ultima_ora":"Ultima ora",
        "intesita_pioggia":"Intensità attuale",
        "massima_intesita_pioggia":"Intensità Massima",
        "humidex":"Humidex",
        "heatindex":"Indice di calore",
        "wind_chill_min": "Wind chill min",
        "dew_point_min": "Dew point min",
        "dew_point_max": "Dew point max",
        "solarRadius":"Radiazione solare",
        "uv":"Indice UV",
        "uvmax":"Indice UV max"
    }
  },
  it: {
    translation: {
        'registrati': "Registrati",
        'password_dimenticata': "Password dimenticata?",
        'accedi': "Accedi",
        'email': "E-mail",
        'password': "Password",
        "termini_condizioni": "Termini e condizioni",
        "conferma": "Conferma",
        "salva": "Salva",
        "faq": "F.A.Q",
        "subTitleFaq": "Qui puoi trovare le risposte alle domande più frequenti.",
        "torna_indietro": "Torna indietro",
        "401_subtitle": "Stai provando ad accedere ad una pagina che richiede l'autenticazione, prosegui la navigazione usando la barra in alto o torna indietro.",
        "401_subtitle_admin": "Non hai i permessi sufficienti per visualizzare questa pagina.",
        "401_title": "Errore 401 non sei autorizzato",
        "erroreServer": "Errore server",
        "500_title": "Errore 500 problema interno del server",
        "500_subtitle": "La richiesta che hai inviato, non è andata a buon fine, riprova o contatta l'assistenza del servizio tecnico, descrivendo il problema.",
        "non_trovato": "Non trovato",
        "404_title": "Errore 404 risorsa cercata non trovata",
        "404_subtitle": "Il percorso ricerca non è stato trovato, controlla di aver digitato correttamente il link, riprova.",
        "logout": "Logout",
        "vai": "Vai",
        "overview":"Home",
        "problemi_di_elaborazione":"Problemi di elaborazione,riprova",
        "temperature": "Temperatura",
        "humidity": "Umidità",
        "wind":"Vento",
        "pressure": "Pressione",
        "rain": "Pioggia",
        "attuale": "Attuale",
        "massima": "Massima",
        "minima": "Minima",
        "dew_point": "Dew point",
        "wind_chill": "Wind chill",
        "giorno": "Giorno",
        "mese": "Mese",
        "anno": "Anno",
        "umidity":"Umidità",
        "radar":"Radar",
        "webcam":"Webcam",
        "idrometro":"Idrometro",
        "centraline_online":"Centraline online",
        "idrometri_online":"Idrometri online",
        "pioggia_ultima_ora":"Ultima ora",
        "intesita_pioggia":"Intensità attuale",
        "massima_intesita_pioggia":"Intensità Massima",
        "humidex":"Humidex",
        "heatindex":"Indice di calore",
        "wind_chill_min": "Wind chill min",
        "dew_point_min": "Dew point min",
        "dew_point_max": "Dew point max",
        "solarRadius":"Radiazione solare",
        "uv":"Indice UV",
        "uvmax":"Indice UV max"
    }
  }
};
i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'it',
    fallbackLng: 'it',
    interpolation: {
      escapeValue: false
    }
  });
