import { Suspense, lazy } from 'react';
import type { PartialRouteObject } from 'react-router';
import DashboardLayout from './components/dashboard/DashboardLayout';
import LoadingScreen from './components/LoadingScreen';
import FAQ from "./pages/FAQ";
import Contact from "./pages/Contact";

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Dashboard pages

const DashboardHome = Loadable(lazy(() => import('./pages/dashboard/DashboardHome')));
const DashboardWebcam = Loadable(lazy(() => import('./pages/dashboard/DashboardWebcam')));

// Error pages

const AuthorizationRequired = Loadable(lazy(() => import('./pages/guard/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/guard/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/guard/ServerError')));

// Other pages

const routes: PartialRouteObject[] = [
  {
    path: 'contact',
    element: <Contact />
  },
  {
    path: 'faq',
    element: <FAQ />
  },
  {
    path: '*',
    element:  <DashboardLayout />,
    children: [
      {
        path: '/',
        element: <DashboardHome />,
      },
      {
        path: '/webcam',
        element: <DashboardWebcam />,
      },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
