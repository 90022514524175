import { useEffect } from 'react';
import type { FC } from 'react';
import { useRoutes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import {DeviceUUID} from 'device-uuid'
import './i18n';
import useScrollReset from './hooks/useScrollReset';
import routes from './routes';
import { createCustomTheme } from './theme';
import useSettings from "./hooks/useSettings";
import {CssBaseline, ThemeProvider} from "@mui/material";


const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  useScrollReset();

  useEffect(() => {}, []);

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });
  //setup uuid
  if(!localStorage.getItem("uuid")){
    const du = new DeviceUUID().parse();
    const dua = [
      du.language, du.platform, du.os, du.cpuCores, du.isAuthoritative, du.silkAccelerated, du.isKindleFire, du.isDesktop, du.isMobile,
      du.isTablet, du.isWindows, du.isLinux, du.isLinux64, du.isMac, du.isiPad, du.isiPhone, du.isiPod, du.isSmartTV,
      du.pixelDepth, du.isTouchScreen];
    localStorage.setItem("uuid",du.hashMD5(dua.join(':')))
  }
  return (
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <Toaster position="top-center" />
        {content}
    </ThemeProvider>
  );
};

export default App;
