import { useEffect } from 'react';
import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import {Box, Container, Divider, Typography} from '@mui/material';
import Markdown from 'react-markdown/with-html';
import i18n from "i18next";
import Footer from "../components/Footer";
import {experimentalStyled} from "@mui/material";

const MarkdownWrapper = experimentalStyled('div')(
    ({ theme }) => (
        {
            color: theme.palette.text.primary,
            fontFamily: theme.typography.fontFamily,
            '& h2': {
                fontSize: theme.typography.h2.fontSize,
                fontWeight: theme.typography.fontWeightBold,
                lineHeight: theme.typography.h2.lineHeight,
                marginBottom: theme.spacing(3)
            },
            '& h3': {
                fontSize: theme.typography.h3.fontSize,
                fontWeight: theme.typography.fontWeightBold,
                lineHeight: theme.typography.h3.lineHeight,
                marginBottom: theme.spacing(3)
            },
            '& p': {
                fontSize: theme.typography.body1.fontSize,
                lineHeight: theme.typography.body1.lineHeight,
                marginBottom: theme.spacing(2)
            },
            '& li': {
                fontSize: theme.typography.body1.fontSize,
                lineHeight: theme.typography.body1.lineHeight,
                marginBottom: theme.spacing(1)
            }
        }
    )
);
const FAQ: FC = () => {
  useEffect(() => {
  }, []);
  return (
    <>
      <Helmet>
          <title>{`${i18n.t("faq")} | Limet`}</title>
      </Helmet>
        <Box
            sx={{
                backgroundColor: 'background.paper',
                minHeight: '100%'
            }}
        >
            <div>
                <Box sx={{ py: 3 }}>
                    <Container maxWidth="md">
                        <Typography
                            align="center"
                            color="textPrimary"
                            sx={{
                                fontWeight: 'fontWeightBold',
                                mt: 3
                            }}
                            variant="h2"
                        >
                            <>{i18n.t("faq")}</>
                        </Typography>
                        <Typography
                            align="center"
                            color="textSecondary"
                            sx={{ mt: 3 }}
                            variant="subtitle1"
                        >
                            <>{i18n.t("subTitleFaq")}</>
                        </Typography>

                    </Container>
                </Box>
                <Divider />
                <Box sx={{ py: 3 }}>
                    <Container maxWidth="md">
                        <MarkdownWrapper>
                            <Markdown source={`
## ABBONAMENTI
### Come si sottoscrive un nuovo abbonamento

Basta scegliere il tipo di sottoscrizione più adatta alle proprie esigenze, attraverso questa pagina [abbonamenti](https://shop.micromega.net/subscriptions "I nostri abbonamenti").


### Quali tipologie di abbonamento esistono ?
  - Digitale singola copia
  - Digitale abbonamento annuale
  - Digitale abbonamento annuale + archivio
  - Digitale abbonamento annuale + carta
  - Digitale abbonamento annuale + carta + archivio
  
### Posso copiare il testo degli articoli ?
Accedendo allo sfogliatore clicca sul titolo dell’articolo e ti si aprirà una finestra che contiene l’articolo in formato testo che potrai copiare ed incollare.
I cui contenuti sono coperti da diritto d’autore (utilizzo e riproduzione vietati).

### E’ prevista una funzione di ricerca all’interno del periodico?
Sì è possibile effettuare una ricerca utilizzando l’icona lente d’ingrandimento.

### Posso stampare l’articolo in formato solo testo ?
Cliccando sul titolo è possibile passare alla versione testuale, per effettuare una stampa utilizzare l’icona stampa.

### Posso accedere con il mio smartphone/tablet ai contenuti di MicroMega Digital Edition ?
La sottoscrizione di un abbonamento al servizio MicroMega Digital Edition ti dà automaticamente e da subito la possibilità di leggere il periodico sul tuo smartphone/tablet attraverso l’ apposita applicazione “MicroMega”, per la durata dell’abbonamento sottoscritto. 
Per usufruire dell’abbonamento scarica gratuitamente l’apposita applicazione dall’AppStore o Play Store ed inserisci le tue credenziali d’accesso (e-mail e password) all’interno dell’applicazione nel menù di log in.

### Si può salvare in PDF il periodico digitale o stampare direttamente le pagine di  interesse ?
No, non è possibile salvare il pdf del periodico digitale nè stampare il pdf. 
Scaricando l’app MicroMega Edition da tablet o smartphone si può scaricare in locale in pdf per leggerlo in offline dal proprio dispositivo.

### Da quanti dispositivi posso accedere al mio abbonamento?
L’abbonamento a MicroMega Digital Edition prevede l’accesso da un massimo di tre dispositivi, se ci accede da un quarto dispositivo l’abbonamento verrà momentaneamente bloccato.
E’ possibile resettare il numero di dispositivi associati al tuo abbonamento nella propria area personale nella sezione cliccando su RESET DISPOSITIVI. 
Dopo il reset potrai effettuare l’accesso con le tue credenziali su 3 dispositivi a tua scelta. 
Per assistenza è possibile contattare il servizio di customer care inviando una email a: abbonamenti@micromega.net con oggetto Micro Mega Digital edition /reset dispositivi. 
Il reset avverrà entro 72 ore dalla ricezione della richiesta. //TODO VEDERE

### E’ possibile accedere a periodici precedenti rispetto a quello attualmente in distribuzione?  
Il servizio MicroMega Digital Edition fruito via PC dà la possibilità di accedere ai periodici entro i 30 giorni precedenti all’edizione del giorno. 
L’Archivio Storico a disposizione dei clienti FULL mette a disposizione tutte le copie digitalizzate di MicroMega dal 1986. 
La copia del bimestre viene inserita in Archivio dopo 30 giorni.

### I Magazine accessori o i numeri speciali sono inclusi?  
Sì, tutti i Magazine e gli speciali in uscita con il periodico sono inclusi nell’offerta del servizio MicroMega Digital Edition.

### E’ possibile utilizzare la Digital Edition per effettuare una rassegna stampa?
MicroMega Digital Edition è un servizio di consultazione del periodico digitale e non uno strumento per effettuare rassegne stampa, che devono essere autorizzate dalla redazione del periodico e hanno modalità di invio del materiale e costi diversi rispetto al servizio in oggetto.

### A che ora è disponibile solitamente il periodico digitale di MicroMega Digital Edition? 
Il periodico digitale viene pubblicato in media intorno alle ore 3.00 del mattino del giorno di uscita.

## GESTIONE
### Come si sottoscrive un nuovo abbonamento ?
Basta scegliere il tipo di sottoscrizione più adatta alle proprie esigenze, attraverso questa pagina [abbonamenti](https://shop.micromega.net/subscriptions "I nostri abbonamenti").

### Come si rinnova un abbonamento?
L’abbonamento sarà rinnovato automaticamente. //TODO DA VEDERE
La durata del vostro abbonamento e la modalità di rinnovo dipende dal tipo di sottoscrizione scelta.
 
### Come si disattiva un abbonamento ricorrente?
Per disattivare il tuo abbonamento CLICCA QUI (INSERIRE LINK)  //TODO
Per le altre modalità di disattivazione consulta i TERMINI E CONDIZIONI DI SERVIZIO  (INSERIRE IL LINK)  

### ACQUISTI DA DISPOSITIVI APPLE:
Se hai acquistato il tuo abbonamento su iPad o iPhone, ti ricordiamo che il rinnovo automatico è modificabile esclusivamente dal tuo profilo iTunes al quale solo tu hai accesso.
Ti ricordiamo che la disattivazione deve avvenire almeno 24 ore prima della data prevista per il rinnovo.
Questo è il riferimento Apple sulla gestione/disattivazione degli abbonamenti ricorrenti INSERIRE LINK RICORRENTE //TODO dove troverai tutte le informazioni da seguire per modificare lo stato del tuo abbonamento.
Qualora incontrassi ulteriori problemi, puoi fare riferimento direttamente all’assistenza Apple iTunes http://www.apple.com/it/support/

### ACQUISTI DA DISPOSITIVI ANDROID:
Se hai acquistato il tuo abbonamento su Play Store, ti ricordiamo che il rinnovo automatico è modificabile dal tuo profilo Google al quale solo tu hai accesso.
Ti ricordiamo inoltre che la disattivazione deve avvenire almeno 24 ore prima della data prevista per il rinnovo.
Per disattivare il rinnovo: accedi al Menù di Play Store, seleziona la voce “Le mie App”, entra in Iscrizioni, ricerca l’app MicroMega Digital Edition e annulla il rinnovo automatico.

## DATI DI ACCESSO E PAGAMENTI

### Come avviene il processo di fatturazione?
Il processo di fatturazione è automatico e funziona in questo modo (ove selezionato il campo “richiedi fattura” in sede di acquisto):
La fattura elettronica viene emessa in automatico entro 10 giorni dall’acquisto
 La fattura elettronica viene inviata in automatico tramite Posta Elettronica Certificata all’indirizzo e-mail indicato in fase di registrazione o nella propria area riservata del sito web dell’Agenzia delle Entrate.
I dati di intestazione della fattura sono quelli inseriti come dati di registrazione o come dati di fatturazione alternativi (qualora se ne fossero inseriti di diversi in fase di acquisto). Nel caso non si disponesse del codice SDI la fattura verrà emessa inserendo il codice convenzionale “0000000”e sarà accettata direttamente dall’Agenzia delle Entrate.
Non è previsto l’obbligo di inviare da parte di MicroMega Edizioni una copia cartacea.
L’invio della fattura elettronica è prevista esclusivamente per i clienti con partita iva.
Per i clienti con codice fiscale, che abbiano richiesto la fattura, verrà inviata la fattura cartacea.

### Perché può succedere di non riuscire a concludere l’ordine d’acquisto dell’abbonamento con la carta di credito?
Potrebbero esserci problemi con il pagamento tramite carta di credito. 
Spesso ciò accade in quanto la carta di credito utilizzata è una carta-moneta alla quale non è associata una equivalente carta virtuale che permetta gli acquisti sul web (in particolare quando appare il messaggio di errore “violazione sicurezza”). 
Consigliamo di verificare con la propria banca l’abilitazione della carta agli acquisti sul web. 
Se si utilizza una carta American Express non è richiesto l’inserimento del codice di sicurezza associato alla carta, per cui per concludere correttamente la transazione si potrà procedere senza valorizzare il campo codice di sicurezza. 
In ogni caso si può ovviare scegliendo come modalità di pagamento il Bonifico Bancario o PayPal. 

### Quali sono le modalità di pagamento?
È possibile acquistare un abbonamento tramite:
Carta di Credito:
I circuiti di pagamento adottati sono CartaSi, Visa, Mastercard, American Express attraverso l’utilizzo di PayPal.
Attenzione: verificare che alla propria carta di credito sia associato un profilo di carta virtuale che permetta gli acquisti su Internet. In caso contrario la transazione potrebbe essere bloccata e apparire un messaggio di errore. Per il profilo di carta virtuale rivolgersi alla Banca emettitrice della Carta di Credito.
Bonifico bancario:
Le coordinate bancarie su cui effettuare il pagamento tramite bonifico bancario verranno fornite nella pagina conclusiva al termine della procedura di acquisto, una volta che tale modalità di pagamento sia stata opportunamente selezionata.
PayPal:
Concludendo un acquisto con questo tipo di pagamento verrà visualizzata una pagina del sito PayPal dove inserire l’indirizzo e-mail personale e password del proprio conto, oppure accedere alla procedura per la creazione di uno nuovo, o procedere senza effettuare la registrazione. Dopo la registrazione è possibile procedere ai pagamenti via PayPal senza dover più reinserire i dati, anche per i successivi acquisti. Scegliendo questa modalità di pagamento l’importo viene addebitato direttamente sul conto PayPal.

### Come utilizzare il codice promozionale per accedere agli sconti previsti?
Nella pagina di scelta del metodo di pagamento è previsto l’apposito campo CODICE PROMOZIONALE. Inserendo il codice, il prezzo si sconta come previsto.

## PROBLEMI TECNICI

### E’ possibile modificare l’indirizzo e-mail di accesso al servizio?
Purtroppo non è possibile modificare manualmente l’indirizzo e-mail di accesso. 
Per poter modificare questo dato, consigliamo di contattarci indicandoci il vecchio indirizzo ed il nuovo con cui si vuole eseguire l’accesso.

### E’ possibile modificare la password di accesso al servizio e i miei dati personali?
Tutti i campi (eccetto l’indirizzo e-mail) sono modificabili direttamente dall’utente cliccando, dopo aver effettuato il login ed essere entrati nell’area riservata, sulla voce “Sicurezza”.

### Qual è la procedura per segnalare un problema tecnico?
E’ possibile segnalare un problema tecnico attraverso la sezione Contattaci. Nel segnalare il problema riscontrato vi preghiamo di fornirci tramite l’apposita sezione quanti più dettagli possibile, così da poterlo risolvere insieme ai nostri tecnici. In particolare vi preghiamo di indicare:
– Il Sistema Operativo utilizzato.
– La versione del browser o della app usata per utilizzare il servizio.
– Il tipo di connessione utilizzata (privata, aziendale, protetta).
Sarebbe utile l’indicazione di tutti i passaggi eseguiti e, se possibile, un print screen del problema.


    `} />
                        </MarkdownWrapper>
                    </Container>
                </Box>
            </div>
        </Box>
        <Footer />
    </>
  );
};

export default FAQ;
