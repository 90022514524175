import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
import React, {StrictMode} from 'react';
import {HelmetProvider} from 'react-helmet-async';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import {SettingsProvider} from './contexts/SettingsContext';
import { LocalizationProvider } from '@mui/x-date-pickers'
import {StyledEngineProvider} from "@mui/material";
import { createRoot } from 'react-dom/client';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <StrictMode>
        <HelmetProvider>
            <StyledEngineProvider injectFirst>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <SettingsProvider>
                        <BrowserRouter>
                            <App />
                        </BrowserRouter>
                    </SettingsProvider>
                </LocalizationProvider>
            </StyledEngineProvider>
        </HelmetProvider>
    </StrictMode>
);
