import type { FC } from 'react';
import logoWhite from '../res/img/logolimet_notext.png';
import {Box, SxProps, Theme} from "@mui/material";

interface LogoProps {
  sx?: SxProps<Theme>;
}
const LogoWhite: FC<LogoProps> = (props) => (
  <div>
      <Box
          component="img"
          src={logoWhite}
          sx={{
              height: 52,
              width: 52
          }}
      />
  </div>
);

export default LogoWhite;
